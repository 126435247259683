<template>
  <div class="product-list">
    <product-item v-for="item in projects" :key="item.name" :project="item" :img-height="imgHeight" ></product-item>
  </div>
</template>

<script>
  import ProductItem from "@/components/common/productList/ProductItem";
  export default {
    name: "ProductAll",
    props:['projects','imgHeight'],
    components:{
      ProductItem
    },
  }
</script>

<style scoped>
  .product-list{
    display: flex;
    flex-wrap: wrap;
  }
</style>