<template>
  <div class="project">
    <div class="list">
      <hight-light text="产品应用" bg-text="Case Center" :is-bg="true" class="title"/>
      <ProductAll :projects="projects" img-height="197px"></ProductAll>
    </div>
  </div>
</template>

<script>
  import ProductAll from "@/components/common/productList/ProductAll";
  import HightLight from "@/components/common/hightLight/HightLight";
  import {projects} from "@/assets/project";

  export default {
    name: "Project",
    components:{
      ProductAll,
      HightLight
    },
    data(){
      return {projects}
    }
  }
</script>

<style scoped>
  .project{
    display: flex;
    justify-content: center;
  }
  .list{
    width: 1200px;
    text-align: center;
    margin-bottom: 30px;
  }
  .list p{
    font-size: 30px;
  }
  .title{
    margin-right: 15px;
  }
</style>
