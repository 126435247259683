export {
  projects
}
let projects=[
  {
    image:require('@/assets/swiper/swiper1.png'),
    name:'流行病学调查分析管理一体化平台',
    id:101
  },
  {
    image:require('@/assets/swiper/swiper1.png'),
    name:'流行病学调查分析管理一体化平台',
    id:101
  },
  {
    image:require('@/assets/swiper/swiper1.png'),
    name:'流行病学调查分析管理一体化平台',
    id:101
  },

]
