import fa from "element-ui/src/locale/lang/fa";

export {
  introduces
}

let introduces=[
  {
    title:'公司简介',
    text1: '湖北富瑞尔科技有限公司成立于2008年，企业注册资金伍佰零壹万元，公司以武汉大学、武汉纺织大学为依托，充分发挥依托单位在测绘、遥感、GIS、计算机、电子等方面的研发、技术和装备优势。公司是高新技术企业、双软企业、武汉市“小巨人”企业、武汉市“瞪羚”企业、湖北省测绘协会会员、武汉市软件协会会员，先后通过了ISO9001质量体系和知识产权管理体系认证。公司企业信用等级为AAA，纳税信用等级为A。',
    text2:'公司具有完善的组织和管理结构，现有固定员工160余人，专业分布于测绘、遥感、GIS、计算机、电子等方面，其中25%具有研究生以上学位，10%以上具有副教授或高级工程师以上高级职称，注册测绘师6人。',
    img:require('@/assets/introduce/swiper1.png'),
    isLeft: true,
    isWhite: false
  },
  {
    title: '服务范围',
    text1:'公司具有乙级测绘资质，业务范围涵盖摄影测量与遥感、工程测量、地籍测量、遥感图像处理软件开发、地理信息系统与工程、传感器网络系统开发与工程实施、以及空间信息工程应用相关的监理与咨询服务。',
    text2:'公司产品与工程服务可广泛应用于国土资源、环境保护、农业、林业、交通、水利、电力、智慧城市、智慧园区、测绘等诸多领域。',
    img:require('@/assets/introduce/swiper2.png'),
    isLeft: false,
    isWhite: true
  },
  {
    title:'公司成果',
    text1:'公司成立以来，本着“以人为本、为客户创造价值、互利共赢、长久发展”的经营理念，足迹遍布湖北、浙江、安徽、福建、河南、江西等多个省份，涉及电力、规划、国土、生态环保、水利、卫生、公路、海洋、军品等多个行业，完成遥感图像软件开发、GIS软件开发、GIS建库、三维建模、遥感数据获取（LiDAR、航空摄影、无人机航空摄影）、地籍测量、摄影测量、国情普查、土地确权、电力选线、电力线普查、工程测量等各类项目共计100余项，服务态度和成果深受用户褒奖。',
    img:require('@/assets/introduce/swiper3.png'),
    isLeft: true,
    isWhite: false
  }
]
