<template>
  <div>
    <hight-light :is-bg="true" text="公司简介" bg-text="About Us" />
    <!-- <product class="product"/> -->
    <div class="introduce">
      <div class="content">
        <div class="img">
          <img src="@/assets/introduce/swiper1.png" alt="" />
        </div>
        <div class="text">
          <strong>公司简介</strong>
          <p>{{ text1 }}</p>
          <p>{{ text2 }}</p>
          <div style="display: flex; justify-content: flex-end">
            <el-button type="primary" @click="pathto">查看更多</el-button>
          </div>
        </div>
      </div>
    </div>
    <project />
    <div style="display: flex; justify-content: center">
      <el-button type="primary" @click="pathtoproduct" style="padding-left:50px;padding-right:50px;">查看更多</el-button>
    </div>

    <Contact />
  </div>
</template>

<script>
import Product from "@/components/product/Product";
import About from "@/components/about/About";
import Project from "@/components/project/Project";
import Contact from "@/components/contact/Contact";
import HightLight from "@/components/common/hightLight/HightLight";
import { introduces } from "@/assets/about";
export default {
  name: "Home",
  components: { Contact, Project, About, Product, HightLight },
  data() {
    return {
      introduces,
      text1:
        "湖北富瑞尔科技有限公司成立于2008年，企业注册资金伍佰零壹万元，公司以武汉大学、武汉纺织大学为依托，充分发挥依托单位在测绘、遥感、GIS、计算机、电子等方面的研发、技术和装备优势。公司是高新技术企业、双软企业、武汉市“小巨人”企业、武汉市“瞪羚”企业、湖北省测绘协会会员、武汉市软件协会会员，先后通过了ISO9001质量体系和知识产权管理体系认证。公司企业信用等级为AAA，纳税信用等级为A。",
      text2:
        "公司具有完善的组织和管理结构，现有固定员工160余人，专业分布于测绘、遥感、GIS、计算机、电子等方面，其中25%具有研究生以上学位，10%以上具有副教授或高级工程师以上高级职称，注册测绘师6人。",
    };
  },
  methods: {
    pathto() {
      this.$router.push("/about");
    },
    pathtoproduct(){
      this.$router.push("/project");
    }
  },
};
</script>

<style scoped>
.product {
  height: 770px;
  overflow: hidden;
}
.about {
  height: 644px;
  overflow: hidden;
  background-color: #ffffff;
}

.bgc {
  background-color: #d6e1f7;
}
.introduce {
  display: flex;
  /* height: 549px; */
  /* width: 100%; */
  height: 400px;
  align-items: center;
  justify-content: center;
}
.content {
  font-size: 14px;
  color: #666666;
  width: 1200px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content img {
  /* height: 350px;
  width: 600px; */
  max-height: 350px;
  max-width: 600px;
}
strong {
  font-size: 25px;
  font-weight: bold;
}
.text {
  /* margin-right: 50px; */
  text-align: justify;
  margin: 20px;
  width: 500px;
}
</style>
